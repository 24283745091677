import React from "react";
import { Helmet } from "react-helmet";
import favicon from '~image/favicon.png';
import Header from "../Header";
export default function Layout({
  children,
}) {
  return (
    <>
      <Helmet>
        <title>Axcer</title>
        <link rel="icon" type="image/png" href={favicon} />
        <script src="https://use.fontawesome.com/e7b8c1b2d3.js"></script>
        <script type="application/ld+json">
          {`
        {
          "@context": "https://schema.org",
          "@type": "Company",
          "url": "https://axcer.io/",
          "name": "Axcer Innovations",
          "contactPoint": {
            "@type": "ContactPoint",
            "telephone": "+94 7182 17615",
             "email": "info@axcer.io",
            "contactType": "email"
          }
        }
      `}



        </script>

        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/css/intlTelInput.css" />
        <script src="https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/intlTelInput.min.js"></script>



























      </Helmet>
      <Header />
      {children}
    </>
  )
}
