import React from "react";
import GlobalHeaderContext, { headerDefault } from "../../../context/GlobalHeaderContext";
import Link from "../Link";
import HeaderButton from "../Header/InnerPageHeader"
import Whatsapp from "~sections/whatsapp";

const PageWrapper = ({ children, headerConfig = null, innerPage = false, innerPageFooter = false }) => {
  const innerPageDefault = {
    headerClasses: "light-header site-header--menu-end site-header--button-sep site-header--sticky position-relative",
    containerFluid: false,
    darkLogo: true,
    buttonBlock: (
      <HeaderButton
        className="ms-auto d-none d-xs-inline-flex"
        btnText="Start New Project"
        mr="15px"
        mrLG="0"
        btnLink="/contact"
        as={Link}
      // btnTwoText="Get Quotation"
      // btnTwoLink="/contact"


      />
    ),
    // buttonBlock: (<HeaderButton as={Link} btnText="Purchase" />)
  }
  const activeHeader = (innerPage ? innerPageDefault : headerDefault);
  const sitectx = React.useContext(GlobalHeaderContext);

  React.useEffect(() => {
    sitectx.changeHeader({ ...activeHeader, ...headerConfig });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      {/* <Nav /> */}
      <Whatsapp />
      {children}
    </>
  )
}

export default PageWrapper;